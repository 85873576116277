<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'month',
            'date',
            'receipt',
            'invoice',
            helpers.building,
            getHelperObject('building_type', 'building.building_type_id'),
            getHelperObject('property_unit', 'building.property_unit_id'),
            'imputation',
            'note',
            'concept_month',
            helpers.currencyValue
            //'_order'
          ]"
          :config="{
            name: 'payment',
            url: 'admin/reports/building_payment_spill',
            route: '/admin/reports/building_payment_spill',
            display_name: 'Detalle derrame de pagos',
            params: { _lists: 'months,building_types,property_units,imputations' },
            filters: [
              { key: 'month', multiple: false, default: 'response.data.month_id', forced: true, list: 'months', callback: this.onGetData },
              { key: 'building_code', use: 'building.identifier' },
              { key: 'building_type', config: { select_all: true } },
              { key: 'property_unit', config: { select_all: true } },
              { key: 'imputation', config: { select_all: true } }
            ],
            noCreate: true,
            noOptions: 'hide',
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'
import { order } from '@/app/_utils/global-utils'

export default {
  name: 'BuildingPaymentSpill',
  mixins: [crudMixin],
  data() {
    return {
      month: {}
    }
  },
  methods: {
    onGetData(month_id) {
      if (month_id) {
        this.month = { month_id, _period_name: this.getPeriodName(month_id) }
        this.$refs.crud_table.getData(false, { month_id }, true)
      }
    },

    parseItems(parent, items) {
      this.$set(this.$refs.crud_table.options, 'file_name', this.property.name + ' - Reporte Detalle derrame de pagos - ' + this.getPeriodName(this.$refs.crud_table.response.data.month_id) + ' ' + this.$moment().format('YYYYMMDD HHmmss'))

      let new_items = []
      for (let item in items) {
        item = items[item]
        const item_items = []
        for (let invoice_detail in item.invoice_details) {
          invoice_detail = item.invoice_details[invoice_detail]
          let receipt = ''
          if (invoice_detail.payment?.receipt) receipt = invoice_detail.payment.receipt.consecutive
          item_items.push({ id: invoice_detail.id, imputation: this.parsers.readHelper(invoice_detail.imputation_id, 'imputations', 'name'), month: this.parsers.periodName(invoice_detail.month_id), date: invoice_detail.payment?.payment_date || '', receipt, invoice: item.consecutive, building: item.building, note: invoice_detail.description, concept_month: this.parsers.periodName(invoice_detail.origin_month_id), value: invoice_detail.value })
        }
        for (let invoice_detail in item.aux_invoice_details) {
          invoice_detail = item.aux_invoice_details[invoice_detail]
          let receipt = ''
          if (invoice_detail.parent_credit) {
            receipt = (invoice_detail.parent_credit.invoice_detail_type_id === 'NOT' ? 'NCR ' : 'SFV ') + this.parsers.numeric(invoice_detail.parent_credit.value || 0, 'currency_signed', 0)
          }
          item_items.push({ id: invoice_detail.id, imputation: this.parsers.readHelper(invoice_detail.imputation_id, 'imputations', 'name'), month: this.parsers.periodName(invoice_detail.month_id), date: invoice_detail.payment?.payment_date || '', receipt, invoice: item.consecutive, building: item.building, note: invoice_detail.description, concept_month: this.parsers.periodName(invoice_detail.origin_month_id), value: invoice_detail.value })
        }
        new_items = [...new_items, ...order(item_items, 'id')]
      }

      return new_items
    }
  }
}
</script>
